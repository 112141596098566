<template>
  <ProjectDetail projectType="invest"/>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import ProjectDetail from "@/pages/project/Detail.vue";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";

function Component(initialize) {
  this.name = "pageInvestDetail";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {ProjectDetail},
  setup() {
    const component = new Component(async () => {
      if (!await store.getters.investProjectAvailable("detail")) {
        store.commit("openModal", {
          name: "InvestProjectPauseNotice",
          routerReplace: true,
          preventClose: true,
          params: {
            keep: true
          }
        });
      }
    });

    return {component};
  }
});
</script>